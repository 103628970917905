import { createRouter, createWebHistory } from "vue-router"
import Home from "../views/Home.vue"
const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      header: true,
    },
  },
  {
    path: "/Auction",
    name: "Auction",
    component: () => import("@/views/Auction.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/Congrats",
    name: "Congrats",
    component: () => import("@/views/dialogs/Congrats.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/worldView",
    name: "worldView",
    component: () => import("@/views/WorldView.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/card",
    name: "card",
    component: () => import("@/views/Card.vue"),
    meta: {},
  },
  {
    path: "/intro",
    name: "intro",
    component: () => import("@/views/Intro.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/Sorry",
    name: "sorry",
    component: () => import("@/views/dialogs/Sorry.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/End",
    name: "End",
    component: () => import("@/views/dialogs/End.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/Completed",
    name: "Completed",
    component: () => import("@/views/dialogs/Completed.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/Pay",
    name: "Pay",
    component: () => import("@/views/dialogs/Pay.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/Welcome",
    name: "Welcome",
    component: () => import("@/views/dialogs/Welcome.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/List",
    name: "List",
    component: () => import("@/views/dialogs/List.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/Hero",
    name: "Hero",
    component: () => import("@/views/Hero.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/result",
    name: "result",
    component: () => import("@/views/Result.vue"),
    meta: {
      header: true,
    },
  },
  // {
  //   path: "/wallet",
  //   name: "wallet",
  //   component: () => import("@/views/Wallet.vue"),
  //   meta: {
  //     header: true,
  //   },
  // },
  {
    path: "/policy",
    name: "policy",
    component: () => import("@/views/Policy.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/Terms.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/award/",
    name: "award",
    component: () => import("@/views/Award.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/account/",
    name: "account",
    component: () => import("@/views/Account.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/invite",
    name: "invite",
    component: () => import("@/views/Invite.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/task",
    name: "task",
    component: () => import("@/views/task/index.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/points-store",
    name: "points-store",
    component: () => import("@/views/Prize_draw.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/rebate",
    name: "rebate",
    component: () => import("@/views/Rebate.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/purchase",
    name: "purchase",
    component: () => import("@/views/purchase/index.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/tarot-system",
    name: "tarot-system",
    component: () => import("@/views/tarot-system/index.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/stake",
    name: "stake",
    component: () => import("@/views/tarot-system/new-index.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/partner",
    name: "partner",
    component: () => import("@/views/partner/index.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/purchase",
    name: "purchase",
    component: () => import("@/views/purchase/index.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/paySuccess",
    name: "paySuccess",
    component: () => import("@/views/pay-status/index.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/payFail",
    name: "payFail",
    component: () => import("@/views/pay-status/index.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/rewards",
    name: "rewards",
    component: () => import("@/views/rewards/index.vue"),
    meta: {
      header: true,
    },
  },
  {
    path: "/summoner-program",
    name: "summoner-program",
    component: () => import("@/views/summoner-program/index-second-phase.vue"),
    meta: {
      header: true,
    },
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
