<template>
  <div class="m-view-header box-border xs:!h-14 xs:!px-4">
    <div class="header-left flex gap-7">
      <a href="/" class="mr-3">
        <img
          src="@/assets/img/logo.png"
          alt=""
          srcset=""
          class="header-logo xs:!w-28"
        />
      </a>

      <van-button v-if="testCode" class="header-left-item" @click="getTestCodes"
        >Activation Code</van-button
      >
      <van-button
        v-if="checkCodeBtn"
        class="header-left-item"
        @click="toCheckCode"
        >Code Verification</van-button
      >
      <van-button
        v-if="openCheck"
        class="header-left-item"
        @click="handleCheckWL"
        >CHECK WL</van-button
      >
      <van-button v-if="openSummon" class="header-left-item" @click="goToSummon"
        >SUMMON</van-button
      >

      <div
        :class="[
          'header-left-item cursor-pointer hover:!text-white xs:!hidden',
          isSelectPath('home', '/') && '!text-white',
        ]"
        @click="goPage('/', false)"
      >
        HOME
      </div>

      <div class="header-left-item xs:!hidden">
        <span
          :class="[
            'event cursor-pointer hover:text-white',
            isSelectPath('event') && 'text-white',
          ]"
        >
          EVENT
        </span>
        <div class="ui fluid popup !px-0 !py-[22px]">
          <div class="flex">
            <div
              class="px-[30px] flex flex-col gap-[10px] border-r border-white/25"
            >
              <!-- <div class="title">QUEST</div> -->

              <div
                :class="['item', isSelectPath('event', '/task') && 'active']"
                @click="goPage('/task', false)"
                v-if="openQuest"
              >
                QUEST
              </div>
            </div>
            <div class="px-[30px] flex flex-col gap-[10px]">
              <div
                :class="[
                  'item',
                  isSelectPath('event', '/summoner-program') && 'active',
                ]"
                @click="goPage('/summoner-program')"
              >
                SUMMONER PROGRAM
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header-left-item xs:!hidden" v-show="openBetaTest">
        <span
          :class="[
            'game cursor-pointer hover:text-white',
            isSelectPath('game') && 'text-white',
          ]"
        >
          GAME
        </span>

        <div class="ui fluid popup !px-0 !py-[22px]">
          <div class="flex">
            <div
              class="px-[30px] flex flex-col gap-[10px] border-r border-white/25"
            >
              <div class="title">BETA TEST</div>

              <div
                :class="['item', isSelectPath('game', '/rewards') && 'active']"
                @click="goPage('/rewards')"
                v-if="openQuest"
              >
                REWARDS
              </div>
              <div
                :class="['item', isSelectPath('game', '/partner') && 'active']"
                @click="goPage('/partner')"
                v-if="openQuest"
              >
                PARTNERS PROGRAM
              </div>
              <div
                :class="[
                  'item',
                  isSelectPath('game', '/tarot-system') && 'active',
                ]"
                @click="goPage('/tarot-system')"
                v-if="openQuest"
              >
                TAROT SYSTEM
              </div>
            </div>
            <div class="px-[30px] flex flex-col gap-[10px]">
              <div class="title">ALPHA TEST</div>

              <div class="ui menu-font link list !mt-0">
                <div
                  :class="['item', isSelectPath('game', '/award') && 'active']"
                  @click="goPage('/award')"
                >
                  REWARDS
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        :class="[
          'header-left-item cursor-pointer hover:!text-white xs:!hidden',
          isSelectPath('stake', '/stake') && '!text-white',
        ]"
        @click="goPage('/stake')"
      >
        STAKE
      </div>

      <div
        :class="[
          'header-left-item cursor-pointer hover:!text-white xs:!hidden',
          isSelectPath('points-store', '/points-store') && '!text-white',
        ]"
        @click="goPage('/points-store')"
      >
        POINTS STORE
      </div>
    </div>
    <div class="header-right xs:!hidden">
      <div
        class="cursor-pointer header-btn !bg-transparent !px-2 border-[0.5px] font-['Celtic-Garamond-the-2nd']"
        @click="goPage('/purchase')"
        style="border-image: linear-gradient(to top, #ce9d68, #f6f1b0) 1"
      >
        <div
          class="bg-gradient-to-r from-[#f6f1b0] to-[#ce9d68] w-full h-full"
          style="
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          "
        >
          PURCHASE
        </div>
      </div>

      <!--van-button v-if="showAuction" class="header-btn" @click="handleAuction">AUCTION</van-button-->
      <!-- <w3m-account-button v-if="account" /> -->

      <van-button
        v-if="openEthereum && global.username"
        class="header-btn header-btn-img"
        @click="setWalletVisible(true)"
      >
        <img src="@/assets/img/icon_wallet_small.png" />
      </van-button>
      <van-button v-if="!global.username" class="header-btn" @click="toLogin"
        >LOG IN</van-button
      >
      <div
        class="ui mini icon top left pointing dropdown header-btn"
        :style="{ display: display }"
      >
        <i class="user circle large icon"></i>
        {{ global.username }}
        <div class="ui menu">
          <div class="item menu-font" @click="$router.push('/account')">
            ACCOUNT
          </div>
          <div class="ui divider"></div>
          <div class="item menu-font" @click="logout">LOG OUT</div>
        </div>
      </div>

      <div
        class="*:object-cover *:*:max-h-7 *:mr-6 *:grayscale flex items-center pl-4 border-l border-l-white/30"
      >
        <a href="https://x.com/Castileofficial" target="_blank">
          <img src="@/assets/img/icon_x.png" alt="X" />
        </a>
        <a href="https://discord.gg/castileofficial" target="_blank">
          <img src="@/assets/img/icon_Discord.png" alt="Discord" />
        </a>
        <!-- <a
          href="https://nytrolab.gitbook.io/nytro-lab.whitepaperen"
          target="_blank"
        >
          <img src="@/assets/img/icon_whitepaper.png" alt="Whitepaper" />
        </a> -->
        <a href="https://www.youtube.com/@Castileofficial" target="_blank">
          <img src="@/assets/img/icon_youtube.png" alt="YouTube" />
        </a>
        <a href="https://medium.com/@Castileofficial" target="_blank">
          <img src="@/assets/img/icon_Medium.png" alt="Medium" class="!mr-0" />
        </a>
      </div>
    </div>

    <div class="xs:!block hidden bg-[#b7afa4] p-2" @click="openMenu">
      <i class="sidebar icon !mr-0" />
    </div>

    <teleport to="body">
      <div
        class="ui right vertical inverted sidebar overlay menu !z-50 p-4 xs:p-2 xs:*:!mb-2 xs:*:!text-xs"
      >
        <div>
          <div
            class="cursor-pointer header-btn !bg-transparent !px-2 border-[0.5px] font-['Celtic-Garamond-the-2nd'] xs:!text-base xs:text-center"
            style="border-image: linear-gradient(to top, #ce9d68, #f6f1b0) 1"
            @click="goPage('/purchase')"
          >
            <div
              class="bg-gradient-to-r from-[#f6f1b0] to-[#ce9d68] w-full h-full leading-[2]"
              style="
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              "
            >
              PURCHASE
            </div>
          </div>
        </div>

        <!-- <w3m-account-button v-if="account" class="item xs:!p-2" /> -->
        <div class="item xs:!p-2" @click="getTestCodes" v-if="testCode">
          Activation Code
        </div>
        <div v-if="checkCodeBtn" class="item xs:!p-2" @click="toCheckCode">
          Code Verification
        </div>
        <div v-if="openCheck" class="item xs:!p-2" @click="handleCheckWL">
          CHECK WL
        </div>
        <div v-if="openSummon" class="item xs:!p-2" @click="goToSummon">
          SUMMON
        </div>

        <div class="item !p-0 !bg-transparent" v-show="openBetaTest">
          <div class="ui accordion">
            <div
              class="title !bg-[#b7afa4] mb-4 !py-4 w-full !font-['Celtic-Garamond-the-2nd'] !text-black xs:!py-2 xs:!mb-2 xs:!text-xs"
            >
              EVENT
            </div>
            <div class="content xs:!pt-0">
              <div class="transition hidden">
                <div
                  class="item xs:!text-xs xs:!py-2"
                  @click="goPage('/task', false)"
                  v-if="openQuest"
                >
                  QUEST
                </div>
                <div
                  class="item xs:!text-xs xs:!py-2"
                  @click="goPage('/summoner-program')"
                >
                  SUMMONER PROGRAM
                </div>
              </div>
            </div>
            <div
              class="title !bg-[#b7afa4] mb-4 !py-4 w-full !font-['Celtic-Garamond-the-2nd'] !text-black xs:!py-2 xs:!mb-2 xs:!text-xs"
            >
              GAME
            </div>
            <div class="content xs:!pt-0">
              <div class="transition hidden">
                <div class="item xs:!text-xs xs:!py-2 xs:opacity-60">
                  BETA TEST
                </div>
                <div
                  class="item xs:!text-xs xs:!py-2"
                  @click="goPage('/rewards')"
                >
                  REWARDS
                </div>
                <div
                  class="item xs:!text-xs xs:!py-2"
                  @click="goPage('/partner')"
                >
                  PARTNERS PROGRAM
                </div>
                <!-- <div
                  class="item xs:!text-xs xs:!py-2"
                  @click="goPage('/invite')"
                >
                  INVITATION
                </div>
                <div
                  class="item xs:!text-xs xs:!py-2"
                  @click="goPage('/rebate')"
                >
                  INVITATION REWARDS
                </div> -->
                <div
                  class="item xs:!text-xs xs:!py-2"
                  @click="goPage('/tarot-system')"
                >
                  TAROT SYSTEM
                </div>

                <div
                  class="item xs:!text-xs xs:!py-2 xs:opacity-60"
                  v-if="openAlpha"
                >
                  Alpha Test
                </div>
                <div
                  class="item xs:!text-xs xs:!py-2"
                  v-if="openAlpha"
                  @click="goPage('/award')"
                >
                  REWARDS
                </div>
              </div>
            </div>

            <div
              class="!bg-[#b7afa4] mb-4 !py-4 w-full !font-['Celtic-Garamond-the-2nd'] !text-black xs:!py-2 xs:!mb-2 xs:!text-xs"
              @click="goPage('/stake')"
            >
              STAKE
            </div>
            <div
              class="!bg-[#b7afa4] mb-4 !py-4 w-full !font-['Celtic-Garamond-the-2nd'] !text-black xs:!py-2 xs:!mb-2 xs:!text-xs"
              @click="goPage('/points-store')"
            >
              POINTS STORE
            </div>
            <div
              v-if="openEthereum && global.username"
              class="header-btn-img item min-h-12 !flex justify-center !bg-[#b7afa4] mb-4 !py-4 w-full !font-['Celtic-Garamond-the-2nd'] !text-black xs:!py-3 xs:!mb-2 xs:!text-xs xs:min-h-[unset]"
              @click="
                () => {
                  openMenu();
                  setWalletVisible(true);
                }
              "
            >
              <img src="@/assets/img/icon_wallet_small.png" class="xs:!h-4" />
            </div>
            <div
              v-if="!global.username"
              class="item block !bg-[#b7afa4] mb-4 !py-4 w-full !font-['Celtic-Garamond-the-2nd'] !text-black xs:!py-2 xs:!mb-2 xs:!text-xs"
              @click="toLogin"
            >
              LOG IN
            </div>
            <div
              v-else
              class="title !bg-[#b7afa4] mb-4 !py-4 w-full !font-['Celtic-Garamond-the-2nd'] !text-black xs:!py-2 xs:!mb-2 xs:!text-xs"
            >
              <i class="user circle large icon"></i>
              {{ global.username }}
            </div>
            <div class="content">
              <div class="transition visible" style="display: block !important">
                <div class="item xs:!text-xs xs:!py-2" @click="toWallet">
                  ACCOUNT
                </div>
                <div class="item xs:!text-xs xs:!py-2" @click="logout">
                  LOG OUT
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </teleport>
  </div>

  <Modal v-if="modalStatus" @close="closeModal()"></Modal>
  <CheckCode v-if="checkCodeStatus" @close="closeCheckCode()"></CheckCode>
  <BindWallet
    v-if="bindStatus"
    @close="closeBind()"
    :address="bindAddress"
  ></BindWallet>
  <Forget
    :show="forgetStatus"
    @close="closeForget()"
    @toLogin="toLogin()"
    @toForget="toForget()"
  ></Forget>
  <Register
    :show="registerStatus"
    @close="closeRegister()"
    @toLogin="toLogin()"
    @bindWallet="bindWallet"
  >
  </Register>
  <Login
    :show="loginVisible"
    @close="closeLogin()"
    @toRegister="toRegister()"
    @toForget="toForget()"
    @bindWallet="bindWallet"
  ></Login>

  <WalletDialog
    v-model:show="walletVisible"
    ref="WalletDialogRef"
    :disabled="disabledWallet"
    @connect="handleConnect($event)"
    :address="address"
    :aptosAddress="aptosAccount?.address"
  ></WalletDialog>
</template>

<script setup>
import http from '@/utils/http.js';
import { useToast } from 'vue-toastification';
import useModal from '@/store/modules/modal';
import Login from '@/views/dialogs/Login.vue';
import Modal from '@/views/dialogs/Modal.vue';
import useGlobal from '@/store/modules/global';
import useStore from '@/store/modules/auction';
import Forget from '@/views/dialogs/Forget.vue';
import { useRouter, useRoute } from 'vue-router';
import Register from '@/views/dialogs/Register.vue';
import CheckCode from '@/views/dialogs/CheckCode.vue';
import BindWallet from '@/views/dialogs/BindWallet.vue';
import WalletDialog from './components/WalletDialog.vue';
import {
  useControlLoginDialog,
  useControlWalletDialog,
} from '@/hooks/useControlDialog';
import {
  ref,
  onMounted,
  watch,
  nextTick,
  getCurrentInstance,
  inject,
} from 'vue';
/**
 * Apots 钱包上下文
 */
const { account: aptosAccount, disconnect: aptosDisconnect } =
  inject('WalletContext');
const { loginVisible, setVisible } = useControlLoginDialog();
const { walletVisible, setWalletVisible, disabledWallet } =
  useControlWalletDialog(false);
const toast = useToast();
const modal = useModal();
const state = useStore();
const route = useRoute();
const router = useRouter();
const global = useGlobal();
const display = ref('none');
const modalStatus = ref(false);
const loginStatus = ref(false);
const forgetStatus = ref(false);
const registerStatus = ref(false);
const checkCodeStatus = ref(false);
const instance = getCurrentInstance();
const WalletDialogRef = ref(null);

const SELECT_PATH = {
  game: ['/rewards', '/partner', '/tarot-system', '/award'],
  event: ['/task', '/summoner-program'],
  home: ['/'],
};

const isMobile = useMediaQuery('(max-width: 768px)');

const isSelectPath = (key, path) => {
  if (key && !path) return SELECT_PATH[key]?.includes(route.path);
  if (path) return route.path == path;
  return false;
};

const handleConnect = async (type) => {
  if (type == 'ethereum') {
    if (!instance.proxy.address) {
      await disconnect();
      setWalletVisible(false);
      open();
    } else disconnect();
  } else {
    if (!aptosAccount.value?.address) {
      setWalletVisible(false);
      WalletDialogRef.value.AptosConnectRef.selectWallets();
    } else aptosDisconnect();
  }
};

const showPopover = ref(false);
const handleSelectMenu = (menuName) => {
  alert(menuName);
  showPopover.value = false;
};

const openMenu = () => {
  $('.ui.sidebar').sidebar('toggle');
};

function clickListener(e) {
  if (!e.target.classList.value?.includes('menu-btn')) {
    showPopover.value = false;
  }
}
const popoverRef = ref();
const menuBtn = ref();

const promoteCodeAnalysis = () => {
  let url = window.location.href;
  let params = new URLSearchParams(url.split('?')[1]);
  let code = params.get('promote_code');
  if (code) {
    localStorage.setItem('promote_code', code);
  }
};

onMounted(() => {
  if (global.username) {
    global.pullUserInfo();
    display.value = 'block';
  }

  nextTick(() => {
    $('.ui.accordion').accordion();

    $('.header-left-item .game').popup({
      hoverable: true,
      preserve: true,
      exclusive: true,
    });
    $('.header-left-item .event').popup({
      hoverable: true,
      preserve: true,
      exclusive: true,
    });

    $('.ui.dropdown').dropdown({
      on: 'hover',
    });
  });

  promoteCodeAnalysis();
});

watch(
  () => global.username,
  (val) => {
    if (val) display.value = 'block';
    else display.value = 'none';
  }
);
watch(
  () => modalStatus.value,
  (val) => {
    if (!val) modal.align = 'center';
  }
);
watch(
  () => showPopover.value,
  (val) => {
    if (val) {
      nextTick(() => {
        //检测点击空白处事件
        document.body.addEventListener('click', clickListener);
      });
      //这里关闭了登录窗口，不需要可以去掉
      close();
    } else {
      document.body.removeEventListener('click', clickListener);
    }
  }
);

const handleCheckWL = () => {
  if (!state.address) {
    open();
    return false;
  }

  modalStatus.value = true;
  modal.title = 'Checking...';
  modal.conent = 'Please wait';

  http
    .post('/api/mint/getProof', { address: state.address, type: 'check' })
    .then(function (res) {
      if (!res) {
        return true;
      }

      if (res.data.status.code == 10200) {
        const start = state.address.slice(0, 6);
        const end = state.address.slice(-6);
        let str = start + '*****' + end;
        modal.conent =
          'Your <b style="font-family:auto;color:#e7a952">' +
          str +
          '</b> address<br>';

        if (res.data.status.msg == '') {
          modal.title = 'Sorry';
          modal.conent += 'is not in the whitelist';
          return true;
        }

        modal.title = 'Congratulations';
        let type = 'wish summon';

        if (res.data.status.msg == 'fee') type = 'sacred summon';

        modal.conent +=
          'is in the <b style="font-family:auto;color:#e7a952">' +
          type +
          '</b> whitelist';

        return true;
      }
    });
};

const getTestCodes = () => {
  if (!global.uid) {
    toLogin();
    return false;
  }

  modalStatus.value = true;
  modal.title = 'Activation Code';
  modal.conent = 'Please wait, Checking...';

  http.post('/api/user/getTestCodes').then(function (res) {
    if (!res) return false;

    if (res.data.status.code === 10404) {
      modal.conent =
        res.data.status.msg + '<br>Jump to bind wallet after 5 seconds...';
      setTimeout(function () {
        modalStatus.value = false;
        instance.proxy.bindWallet();
      }, 5000);
      return false;
    }
    if (res.data.status.code !== 10200) {
      modal.conent = res.data.status.msg;
      return false;
    }

    let type = 2;
    let tips = '';
    let array = [];
    let font_color = '';
    modal.conent = '';
    modal.align = 'left';
    $.each(res.data.data, function (i, d) {
      const start = d.address.slice(0, 4);
      const end = d.address.slice(-6);
      const str = start + '************' + end;
      modal.conent +=
        '<b style="font-family:auto;color:#e7a952">' + str + '</b> --> ';

      if (d.code) {
        type = 2;
        tips = 'Extra token rewards for binding TAROT';
        $.each(d.tokens, function (k, v) {
          array = v.split('-');
          tips += ' #' + array[0];
          if (array[1] == 3) type = 3;
        });

        font_color = type == 3 ? 'color:#ff651b' : '';
        modal.conent +=
          '<span class="ui popup-tarot visible" data-inverted="" data-tooltip="' +
          tips +
          '" style="font-family: auto;' +
          font_color +
          '" >Code： <b style="font-family:auto;color:#e7a952">' +
          d.code +
          '</b></span><br>';
      } else {
        modal.conent +=
          '<b style="font-family:auto;color:#e7a952">( Not TAROT Holder )</b><br>';
      }
    });
  });
};

const closeModal = () => {
  modalStatus.value = false;
};
const closeRegister = () => {
  registerStatus.value = false;
};

const closeCheckCode = () => {
  checkCodeStatus.value = false;
};

const closeLogin = () => {
  setVisible(false);
};

const closeForget = () => {
  forgetStatus.value = false;
};

const toLogin = () => {
  if (window.innerWidth <= 640) {
    $('.ui.sidebar').sidebar('toggle');
  }

  _resetAllStatus();
  if (loginVisible.value === true) return false;

  setVisible(true);
};

const toRegister = () => {
  _resetAllStatus();
  if (registerStatus.value === true) return false;

  registerStatus.value = true;
};

const toForget = () => {
  _resetAllStatus();
  if (forgetStatus.value === true) return false;

  forgetStatus.value = true;
};

const toCheckCode = () => {
  if (!global.uid) {
    toLogin();
    return false;
  }

  checkCodeStatus.value = true;
};

const _resetAllStatus = () => {
  setVisible(false);
  forgetStatus.value = false;
  registerStatus.value = false;
};

const toWallet = () => {
  router.push('/account');
};

const logout = () => {
  aptosDisconnect();
  disconnect();
  global.logout();
  router.go(0);
};

const goPage = (path, isLogin = true) => {
  if (isMobile.value) {
    openMenu();
  }
  if (!global.username && isLogin) {
    setVisible(true);
  } else {
    $('.ui.modal').modal('hide');
    router.push(path);
  }
};
</script>

<script>
import { useWeb3Modal } from '@web3modal/wagmi/vue';
import {
  watchAccount,
  disconnect,
  signMessage,
  switchNetwork,
  getNetwork,
} from '@wagmi/core';
import { mapWritableState } from 'pinia';
import { Config } from '@/store/config';
import { bindWallet } from '@/hooks/useWalletUtils';
import { createModal } from '@/utils/web3-model';
import { useMediaQuery } from '@vueuse/core';

createModal();

const { open, close } = useWeb3Modal();
export default {
  name: 'layout-header',
  computed: {
    ...mapWritableState(useStore, [
      'address',
      'userInfo',
      'initLogic',
      'showAuction',
      'printUserInfo',
      'resetUserInfo',
    ]),
    ...mapWritableState(useGlobal, ['uid', 'username', 'notNow', 'ethAddress']),
  },
  data() {
    return {
      summon: '',
      testCode: false,
      openCheck: false,
      openSummon: false,
      openEthereum: false,
      openQuest: false,
      openBetaTest: false,
      openAlpha: false,
      checkCodeBtn: false,
      bindStatus: false,
      bindAddress: '',
    };
  },
  mounted() {
    this.getConfig();
    this.resetUserInfo();
    this.printUserInfo();

    const unwatch = watchAccount(async (account) => {
      const cb = () => {
        this.getUserInfo();
        this.$global.id = account.address;
        this.address = account.address;
        this.ethAddress = account.address;

        if (
          localStorage.getItem('isLogin') === 'false' &&
          account.address !== undefined
        ) {
          localStorage.setItem('isLogin', true);
          this.loginRecord();
        }
      };
      if (!account.address) {
        this.showAuction = false;
        if (localStorage.getItem('isLogin') === 'true') this.loginRecord();
        this.address = '';
        this.ethAddress = account.address;

        localStorage.setItem('isLogin', !!account.address);
      }

      if (account.status === 'connected') {
        // 切换网络;
        const nw = getNetwork();
        if (!nw.chains.find((c) => c.id == nw.chain.id)) {
          await switchNetwork({
            chainId:
              process.env.NODE_ENV !== 'production'
                ? nw.chains[1].id
                : nw.chains[0].id,
          });
        }

        const bindStatusRes = await http.post('/api/user/getBindStatus', {
          address: account.address,
        });

        if (bindStatusRes?.data?.status?.code === 10200) {
          cb();
          return;
        }

        // 定义签名的 message
        const timestamp = `${account.address}-${Date.now()}`;
        let res;
        try {
          res = await signMessage({
            message: timestamp,
          });

          if (res) {
            const code = await bindWallet(
              res,
              {
                value: { address: account.address, publickey: account.address },
              },
              timestamp,
              'metamask'
            );
            console.log(code, 'code');
          }
        } catch (err) {
          console.log(err);
          // 拒绝签名则关闭连接
          disconnect();
        }
        cb();
      }
    });
  },
  methods: {
    getConfig() {
      const that = this;
      this.$http.get('/api/guest/getConfig', {}).then(function (res) {
        Config.aptos_contract = res.data.data.aptos_contract;
        that.checkCodeBtn = res.data.data.check_code;
        that.openSummon = res.data.data.open_summon;
        that.openEthereum = res.data.data.open_ethereum;
        that.openQuest = res.data.data.open_quest;
        that.openCheck = res.data.data.open_check;
        that.openAlpha = res.data.data.open_alpha;
        that.openBetaTest = res.data.data.open_betatest;
        that.testCode = res.data.data.test_code;
        Config.android = res.data.data.android;
        that.summon = res.data.data.summon;
        Config.ios = res.data.data.ios;
        Config.all = res.data.data;
      });
    },
    loginRecord() {
      const that = this;
      this.$http
        .post('/api/guest/loginRecord', { address: this.$global.id })
        .then(function (res) {
          if (!res || res.data.status.code != 10200)
            that.$toast.error('reconnect');
        });
    },
    getUserInfo() {
      const that = this;
      this.$http
        .post('/api/guest/getUserInfo', { address: this.$global.id })
        .then(function (res) {
          that.userInfo = res.data.data;
          that.printUserInfo();
          that.initLogic();
        });
    },
    goToSummon() {
      window.open(this.summon, '_blank');
    },
    closeBind() {
      this.bindStatus = false;
    },
  },
};
</script>

<style lang="less" scoped>
.menu-font {
  text-align: left !important;
  font-size: 16px !important;
  font-family: ProximaNova !important;
}

.ui.pointing.dropdown > .menu {
  border-radius: 0;
}

.ui.pointing.dropdown > .menu:after {
  z-index: -1;
}

.ui.pointing.dropdown > .menu:after,
.ui.dropdown .menu,
.ui.fluid.popup:before,
.ui.fluid.popup {
  background: #000;
  border: none !important;
  box-shadow: none !important;
}

.ui.dropdown .menu > .item {
  color: #b7afa4;
  border-radius: 0;
  text-align: center;
}

.ui.dropdown .menu > .item:hover {
  background: #b7afa4;
  color: #1c1c1c;
}

.ui.dropdown .menu > .divider {
  margin: 0;
}

.m-view-header {
  position: sticky;
  top: 0;
  letter-spacing: 1px;
  z-index: 3000;
  height: 80px;
  --van-border-width: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 22px;
  padding-right: 35px;
  background-color: #000;
  width: 100vw;
  flex-shrink: 0;

  .header-left {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    &-item {
      background: transparent;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.4);
      font-weight: bold;
      padding: 0;
      height: 32px;
      margin: 0 !important;
      min-height: unset !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ui.fluid.popup {
      .title,
      .item {
        color: rgba(255, 255, 255, 0.4);
        font-size: 16px;
        white-space: nowrap;
        padding: 10px 0;
      }

      .item {
        cursor: pointer;
        &.active,
        &:hover {
          color: #fff;
        }
      }
    }
  }

  .header-logo {
    height: 49px;
    object-fit: contain;
  }
}

.header-logout {
  width: 40px;
  height: 32px;
  border-radius: var(--wui-border-radius-3xl);
  border: 1px solid var(--wui-gray-glass-005) !important;
  background: var(--wui-gray-glass-005);
  padding: var(--wui-spacing-3xs) var(--wui-spacing-xs) var(--wui-spacing-3xs)
    var(--wui-spacing-xs);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -10px;
}

.header-logout img {
  width: 20px;
}

.header-btn {
  min-height: unset;
  height: 32px;
  background: #b7afa4;
  border-radius: 0px;
  --van-button-border-width: 0;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  line-height: 2;
  margin: 0 !important;
  padding: var(--van-button-normal-padding);
  font-family: ProximaNov;
}

.header-btn-img {
  line-height: 1;
}

.header-btn-img img {
  height: 20px;
}

.header-right {
  display: flex;
  align-items: center;
  column-gap: 20px;
  word-break: keep-all;

  :deep(.van-popover__wrapper) {
    display: flex;
    margin-left: 20px;
  }
}

.dropdownMenu {
  width: fit-content;
  background-color: initial;
  padding: 0;

  img {
    width: 47px;
    height: 33px;
  }
}

.menu-list {
  border-radius: 0;
  background: url('@/assets/img/menu-list-bg.png');
  background-size: 100% 100%;
  width: 207px;
  height: fit-content;
  font-size: 14px;
  font-weight: 400;
  color: #b7afa4;
  line-height: 15px;
  padding: 0 29px;

  .menu-item {
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      bottom: 2px;
      width: 100%;
      height: 2px;
      background-image: url('@/assets/img/menu-item-line.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}

@media screen and (max-width: 1900px) {
  .header-btn {
    width: auto;
    height: 2.4rem;
    font-size: 1.2rem;
    padding: 0 1.6rem;
  }
}

// @media screen and (max-width: 1300px) {
//   .header-btn {
//     width: auto;
//     height: 3rem;
//     font-size: 1.4rem;
//     padding: 0 2rem;
//   }
// }

// @media screen and (max-width: 1024px) {
//   .header-btn {
//     width: auto;
//     height: 3.2rem;
//     font-size: 1.4rem;
//     padding: 0 2rem;
//   }
// }

@media screen and (max-width: 768px) {
  .header-btn {
    width: auto;
    min-width: 6rem;
    height: 2rem;
    font-size: 1rem;
    padding: 0 0.2rem;
  }

  .header-btn-img {
    min-width: unset;
    padding: 0 0.8rem;
  }
}

@media screen and (max-width: 768px) {
  .m-view-header {
    height: 85px;

    .header-logo {
      width: 179px;
    }
  }
  .dropdownMenu {
    min-width: initial;

    img {
      width: 50px;
      height: 33px;
    }
  }

  .menu-list {
    width: 279px;
    font-size: 20px;
    line-height: 21px;
    padding: 0 39px;

    .menu-item {
      height: 84px;
    }
  }
}
</style>

<style lang="less">
.ui.right.sidebar {
  > .item {
    margin-bottom: 1rem;
    background: #b7afa4;
    width: 100%;
    font-family: 'Celtic-Garamond-the-2nd';
    text-align: center;
    color: black;
  }

  > .item + .item {
    margin-bottom: 0;
  }
}

.menu-popover {
  left: calc(100% - 207px) !important;
  transform: translateY(23px);

  .van-popover__arrow {
    display: none;
  }

  .van-popover__content {
    border-radius: 0;
  }
}

@media screen and (max-width: 768px) {
  .menu-popover {
    left: calc(100% - 279px) !important;
    transform: translateY(23px);
  }
}
</style>
