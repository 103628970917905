<template>
  <DialogGuest :show="show" @close="$emit('close')" :lazy-render="false">
    <div class="congrats-wrapper">
      <div class="title">CREATE ACCOUNT</div>
      <div class="title-footer"></div>

      <div class="congrats-dialog-footer">
        <form class="ui form mini" @submit.prevent="debounce(create)">
          <div class="field">
            <label>Email</label>
            <input
              type="email"
              name="email"
              v-model.trim="formData.email"
              required
            />
          </div>
          <!-- <div class="field">
            <label>Email Verification Code</label>
            <div class="ui action input">
              <input type="text" v-model="formData.code" required />
              <div
                class="ui button email-plane mini"
                :class="{
                  disabled: countdown > 0,
                  icon: !(countdown > 0),
                  'email-plane-block': countdown > 0,
                }"
                @click.stop="debounce(sendEmail)"
              >
                <i class="loading spinner icon" v-show="isSendEmailLoading"></i>

                <i
                  v-if="countdown == 0"
                  class="paper plane outline large icon"
                ></i>
                <template v-else>
                  {{ countdown }}
                </template>
              </div>
            </div>
          </div> -->
          <div class="field">
            <label>Password</label>
            <div class="ui icon input">
              <input
                :type="passwordStatus"
                v-model.trim="formData.password"
                required
              />
              <i
                class="eye large icon link"
                :class="{ slash: passwordStatus == 'password' }"
                @click="togglePassword"
              ></i>
            </div>
          </div>
          <div class="field">
            <label>Confirm Password</label>
            <div class="ui icon input">
              <input
                :type="conPasswordStatus"
                v-model.trim="formData.password_confirm"
                required
              />
              <i
                class="eye large icon link"
                :class="{ slash: conPasswordStatus == 'password' }"
                @click="toggleConPassword"
              ></i>
            </div>
          </div>

          <!-- <div
            v-if="Config.all.open_google"
            class="g-recaptcha field"
            data-sitekey="6LfsfzwqAAAAAB648XtD5kF35yxijQ0h7s6mqXci"
            data-action="LOGIN"
            ref="g_element"
          ></div> -->

          <div class="field">
            <div class="ui checkbox">
              <input
                type="checkbox"
                @click="iagree"
                v-model="formData.agree"
                required
              />
              <label
                >I agree the
                <a href="/terms" target="_blank" class="a-tag">Terms of Use</a>
                and the
                <a href="/policy" target="_blank" class="a-tag"
                  >Privacy Policy</a
                ></label
              >
            </div>
          </div>
          <div class="field create-button">
            <button class="ui button mini" type="submit">CREATE</button>
            <p class="font-normal">
              Already have an account?
              <a @click="$emit('toLogin')" class="a-tag">Login here</a>
            </p>
          </div>
        </form>
      </div>
    </div>
  </DialogGuest>
</template>

<script setup>
import { ref, onMounted, toRef, toRefs } from 'vue';
import DialogGuest from '@/components/DialogGuest.vue';
import http from '@/utils/http.js';
import i18n from '@/i18n';
import { useToast } from 'vue-toastification';
import useGlobal from '@/store/modules/global';
import { Config } from '@/store/config';
import { debounce } from '@/utils/debounce-throttle';

const toast = useToast();
const global = useGlobal();

const emit = defineEmits(['close', 'toLogin', 'bindWallet']);
const passwordStatus = ref('password');
const conPasswordStatus = ref('password');
const cnf_countdown = ref(60);
const countdown = ref(0);
const g_element = ref(null);

const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
});
const { show } = toRefs(props);
const formData = ref({
  email: '',
  code: '888888',
  password: '',
  password_confirm: '',
  agree: false,
});
const isSendEmailLoading = ref(false);

const create = () => {
  if (
    !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/.test(
      formData.value.email
    )
  ) {
    toast.warning('The mailbox format is incorrect!');
    return;
  }
  http.post('/api/guest/register', formData.value).then(function (res) {
    if (!res) return false;

    if (res.data.status.code == 10200) {
      localStorage.setItem('authorization', res.data.data.auth);
      toast.success(res.data.status.msg);
      global.setUserInfo(res.data.data);
      emit('close');
      emit('bindWallet');
      promoteBind();
      return false;
    }

    toast.error(res.data.status.msg);
  });
};

const togglePassword = () => {
  passwordStatus.value =
    passwordStatus.value === 'password' ? 'text' : 'password';
};
const toggleConPassword = () => {
  conPasswordStatus.value =
    conPasswordStatus.value === 'password' ? 'text' : 'password';
};

const iagree = () => {
  formData.value.agree = formData.value.agree === true ? false : true;
};

const sendEmail = async () => {
  if (isSendEmailLoading.value) return;

  if (!formData.value.email) {
    toast.warning(i18n.global.t('register_email_null'));
    return false;
  }

  // const gtoken = g_element?.value?.getElementsByClassName(
  //   'g-recaptcha-response'
  // )[0]?.value;

  // if (!gtoken && Config.all.open_google) {
  //   toast.warning('Please perform man-machine verification first');
  //   return;
  // }
  isSendEmailLoading.value = true;
  http
    .post('/api/guest/sendEmail', {
      email: formData.value.email,
      // gtoken: gtoken,
    })
    .then(function (res) {
      if (res.data.status.code == 10200) {
        _countdown();
      } else {
        toast.error(res.data.status.msg);
      }
    })
    .finally(() => {
      isSendEmailLoading.value = false;
    });
};

const promoteBind = () => {
  let code = localStorage.getItem('promote_code');
  if (code) {
    http.post('/api/promote/bind', { code: code }).then(function (res) {
      if (res.data.status.code == 10200) {
        localStorage.removeItem('promote_code');
      }
    });
  }
};

const _countdown = () => {
  countdown.value = cnf_countdown.value;
  let inte = setInterval(function () {
    countdown.value -= 1;
    if (countdown.value <= 0) clearInterval(inte);
  }, 1000);
};
</script>

<style lang="less" scoped>
.m-view-congrats {
  width: 100vw;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.congrats-wrapper {
  width: 500px;
  padding-top: 73px;
  padding-bottom: 70px;
  padding-left: 60px;
  padding-right: 50px;
  box-sizing: content-box;
  background-color: #000;

  .title {
    font-size: 57px;
    font-weight: 400;
    color: #d8d5d0;
    background: linear-gradient(0deg, #f9f6c3 0%, #e7a952 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    line-height: 40px;
  }

  .title-footer {
    height: 5px;
    margin-bottom: 38px;
    background: url('@/assets/img/title-line.png');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.congrats-dialog-footer {
  display: flex;
  justify-content: space-around;

  .van-button {
    width: 241px;
    font-size: 20px;
    padding: 0;
  }
}

.ui.form.mini {
  width: 100%;
}

.ui.form .checkbox label {
  font-family: auto;
}

.ui.form label {
  font-size: 18px;
  font-weight: 400;
  color: #ede7dc !important;
}

.ui.form input {
  color: rgba(255, 255, 255, 0.85);
  background: #343434;
  border-radius: 0;
}

.ui.form input:focus {
  color: rgba(255, 255, 255, 0.85);
  border-color: #85b7d9;
  background: #343434;
  border-radius: 0;
  box-shadow: 0;
}

.ui.button {
  font-family: Celtic-Garamond-the-2nd;
  background: #c5b7a6;
  border-radius: 0;
  letter-spacing: 2px;
  color: #000;
  line-height: 14px;
  width: 300px;
  margin: 0 auto;
}

.create-button {
  text-align: center;
  margin-top: 40px !important;
}

.email-plane {
  text-align: center;
  width: 100px !important;
  border-radius: 0 !important;
}

.email-plane-block {
  display: inline-block !important;
}

.ui.icon.button > .icon {
  margin: 0 auto !important;
}

.a-tag {
  font-family: auto;
  cursor: pointer;
  color: #edb758;
}

.font-normal {
  font-size: 14px;
  margin-top: 30px;
  font-family: auto;
}

.eye.icon {
  color: #5f5f5d;
}
</style>
