import { defineStore } from "pinia"
import http from "@/utils/http.js"


const useGlobal = defineStore("global", {
	persist: [{
		key: 'global',
		paths: ['uid', 'email', 'username', 'notNow']
	}],
	state: () => ({
		uid: 0,
		email: '',
		username: '',
		notNow: false,
		ethAddress: '',
		aptosAddress: '',
		aptosWallet: '',
		twitter_name: '',
		twitter_id: 0,

		other: {}
	}),
	getters: {},
	actions: {
		setUserInfo(data) {
			this.username = data.username
			this.email = data.email
			this.uid = data.uid
			this.twitter_name = data.twitter_name
			this.twitter_id = data.twitter_id
			this.discord_name = data.discord_name
			this.discord_id = data.discord_id

			this.other = data
		},
		pullUserInfo() {
			return new Promise((resolve, reject) => {
				let that = this
				http.post('/api/user/pullUserInfo').then(function (res) {
					that.username = res.data.data.username
					that.email = res.data.data.email
					that.uid = res.data.data.uid
					that.twitter_name = res.data.data.twitter_name
					that.twitter_id = res.data.data.twitter_id
					that.discord_name = res.data.data.discord_name
					that.discord_id = res.data.data.discord_id
					that.other = res.data.data
					resolve(res)
				}).catch((err) => {
					reject(err)
				})
			})
		},
		logout() {
			this.uid = 0
			this.email = ''
			this.username = ''
			localStorage.removeItem('global')
			localStorage.removeItem('authorization')
			localStorage.removeItem('code')
			localStorage.removeItem('AptosWalletName')
		}
	},
})

export default useGlobal
